import React, { useEffect, useState } from 'react';
import './App.css'; // Import the CSS file
import logofav from './logo-fav.svg'; 

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import RequestPage from './Request'; // Import the component for the /request route




const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pages, setPages] = useState([]);
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [postLinks, setPostLinks] = useState([]);

  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: '484307450657720', // Your App ID
        cookie: true,
        xfbml: true,
        version: 'v20.0' // Your API version
      });

      window.FB.getLoginStatus(function(response) {
        statusChangeCallback(response);
      });
    };

    // Load the SDK asynchronously
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      setIsLoggedIn(true);
      fetchPages(response.authResponse.accessToken);
    } else {
      setIsLoggedIn(false);
    }
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
    });
  };

  const fetchPages = (accessToken) => {
    window.FB.api('/me/accounts', function(response) {
      if (response && !response.error) {
        setPages(response.data);
      }
    });
  };

  const login = () => {
    window.FB.login(checkLoginState, {scope: 'pages_show_list, pages_read_engagement, pages_manage_posts'});
  };

  const postToPage = (pageId, accessToken) => {
    window.FB.api(`/${pageId}/feed`, 'POST', { message, link, access_token: accessToken }, function(response) {
      if (response && !response.error) {
        console.log('Post successful:', response);
        const newPostLink = `https://www.facebook.com/${response.id}`;
        setPostLinks(prevPostLinks => [...prevPostLinks, newPostLink]);
      } else {
        console.error('Error:', response.error);
      }
    });
  };

  const postToAllPages = () => {
    pages.forEach((page) => {
      postToPage(page.id, page.access_token);
    });
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={ <div>
      {!isLoggedIn && (  
            <div className="login-container" >
                {/* logo------------------ */}
                <div style={{backgroundColor: 'white', color: 'black',  borderRadius: '25px', paddingLeft: '20px', marginBottom: '10px'}}>
                    <div className="logobox" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                        <table>
                            <tr>
                                <td>
                                    <img className="Animate-logo" src={logofav} alt="Zj"/>
                                </td>
                                <td>
                                    <span className="school">Easy SMM</span>
                                </td>
                            </tr>
                            <tr>
                                <td>                       
                                </td>
                                <td>
                                    <button className="button">
                                      <Link style={{textDecoration:'none', color:'white'}}  to='/request'>
                                        Request
                                      </Link>
                                    </button>                            
                                    <button className="button" onClick={login}>Login with Facebook</button>                            
                                </td>
                            </tr>
                        </table>
                        
                    </div>
                </div>
                <br />

                <div>
                </div>
            </div>
      )}
      {isLoggedIn && (
        <div>            
            {/* logo------------------ */}
            <div style={{backgroundColor: 'white', color: 'black',  borderRadius: '25px', paddingLeft: '20px', marginBottom: '10px'}}>
                <div className="logobox" style={{paddingTop: '50px', paddingBottom: '50px'}}>
                    <img className="Animate-logo" src={logofav} alt="Zj"/>
                    <span className="school">Easy SMM</span>
                </div>
            </div>



          <textarea className="textarea" 
            value={message} 
            onChange={handleMessageChange} 
            placeholder="Write your post here"
            rows="4"
            cols="100"
          />
          <br />
          <input  className="link"
            type="text" 
            value={link} 
            onChange={handleLinkChange} 
            placeholder="Enter your link here"
          />
          <br />
          <button className="fb-post-button" onClick={postToAllPages}>Post to All Pages</button>
          <ul>
            {pages.map((page) => (
              <li key={page.id}>                
                <button  className="fb-post-button" onClick={() => postToPage(page.id, page.access_token)}>Single Post</button> <span>  </span>
                {page.name}
              </li>
            ))}
          </ul>
          {postLinks.length > 0 && (
            <div>
              <h3>Posted Links:</h3>
              <ul>
                {postLinks.map((postLink, index) => (
                  <li key={index}>
                    <a href={postLink} target="_blank" rel="noopener noreferrer">{postLink}</a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div> } />
        <Route path="/request" element={<RequestPage />} />
      </Routes>
    </Router>    
  );
};

export default App;
